// global imports (beyond telia)
import React from "react";
import { navigateToUrl } from "single-spa";

// telia-global imports 
import { Link, LinkProps } from "@purpurds/purpur"

// On <Link>, href is optional, but not on this one
type LinkPropsWithMandatoryHref = Omit<LinkProps, "onClick"> & {
  href: string
}

export const B2bSspaLinkReact = (props: LinkPropsWithMandatoryHref) => {
  const isRelativeUrl = (url: string): boolean => {
    const ABSOLUTE_URL_REGEX = /^([a-zA-Z][a-zA-Z\d+\-.]*?:)?\/{2}?/;
    return !ABSOLUTE_URL_REGEX.test(url);
  }

  const onLinkClick = (event: React.MouseEvent, props: LinkPropsWithMandatoryHref) => {
    if (props.target !== "_blank" && isRelativeUrl(props.href)) {
      type NavigateType = {
        currentTarget: {
          href: string;
        };
        preventDefault: any;
      };

      const shouldOpenInNew = event.metaKey || event.ctrlKey;

      if (!shouldOpenInNew) {
        navigateToUrl(event as unknown as NavigateType);
      } // else if shouldOpenInNew, this event does nothing, and the browser will follow the href
    }
  }

    return (
    <Link {...props} onClick={ (e) => onLinkClick(e, props)} />
  );
};